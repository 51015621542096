/*
Custom css
*/
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary-color {
  color: #1D4BB7 !important;
}

.bold {
  font-weight: 500;
}

.primary-color-bg {
  background-color: #1D4BB7;
  border: 1px solid #1D4BB7;
}

.register-button {
  border-radius: 50px;
  padding: 5px 20px;
  font-weight: 500;
}

.navbar-light .navbar-nav .nav-link {
  padding: 25px;
  color: #fff;
}

.hero {
  background-color: #1D4BB7 !important;
  padding-bottom: 55px;
}

.hero .pad {
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

.hero h1 {
  color: #fff;
  font-size: 60px !important;
  font-weight: 800;
  margin-bottom: 30px;
}

.hero p {
  color: #fff;
}

.hero_img {
  width: 140% !important;
  position: absolute;
  margin-top: 73px !important;
  margin-left: -150px !important;
}

.form-br {
  border-radius: 50px;
}

/* .form-control {
  height: 50px !important;
} */

::placeholder {
  font-size: 14px;
  text-indent: 15px;
}

select {
  appearance: none;
  text-indent: 15px;
}

.search-btn {
  float: right;
  position: relative;
  top: 0;
  right: 0;
  color: #fff;
  margin-top: -59px;
  margin-right: 10px;
  padding: 5px 9px;
  border-radius: 100px;
  font-weight: bold;
}

.primary-bg {
  background-color: #1D4BB7;
}

.nl {
  color: #fff !important;
}

.nx {
  margin-top: 25px;
}

@media(max-width: 768px) {
  .nx {
    margin-left: 25px;
  }
  
}

/* Amazing Benefits Section*/

.benefits {
  margin-top: 130px !important;
  margin-bottom: 100px !important;
}

.benefits h2 {
  color: black;
  font-weight: 700;
}

.benefits h4 {
  color: black;
  font-weight: 700;
  margin-top: 20px;
  font-size: 20px;
}

.benefits p{
  color: #7D7987;
  font-size: 14px;
  margin-top: 20px;
}

.border-around {
  border: 1px solid #cdcdcd;
  border-radius: 20px;
  margin: 30px;
}

.border-right {
  border-right: 1px solid #cdcdcd;
}

.border-top {
  border-top: 1px solid #cdcdcd;
}

.padding-inner {
  padding: 30px;
}

.border-around .hover:hover {
  background-color: #fafafa;
  transition: 1s;
}


/* Pricing Section */

.pricing {
  background-color: #fafafa;
  padding: 100px 30px !important;
  margin-bottom: 100px !important;
}

.pricing h2 {
  color: black;
  font-weight: 700;
}

.pricing p{
  color: #7D7987;
  font-size: 14px;
  margin-top: 20px;
}

.pricing h6 {
  color: #1D4BB7;
  font-weight: 700;
  margin-bottom: 30px;
}

.pricing-plan {
  border: 1px solid #cdcdcd;
  background-color: #fff;
  border-radius: 30px;
  padding: 50px 30px;
  width: 280px;
  height: auto;
  margin-top: -100px;
}

.pricing-plan h2 {
  font-weight: 900;
  color: #000;
  font-size: 40px;
}

.pricing-plan h5 {
  color: #4D4D4D;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 0px;
}

.pricing-plan .small {
  margin-top: 5px !important;
}

.pricing-plan .sub {
  font-size: 16px;
  font-weight: 400;
}

.perk p {
  margin-bottom: -5px;
}

/* Download App Section */

.download-app {
  padding: 100px !important;
  margin-bottom: 100px !important;
}

.download-inner {
  border-radius: 100px;
  padding: 70px 50px 0 70px;
}

.download-inner h1 {
  font-weight: 900;
  color: #fff;
  font-size: 60px;
}

.download-inner p {
  margin-top: 30px;
  color: #fff;
}

/* Why Medflit Section */

.why-medflit {
  margin: 100px 0;
}

.why-medflit h2 {
  color: black;
  font-weight: 700;
}

.medflit-item {
  box-sizing: content-box;
  padding: 15px 15px 15px 30px;
  height: auto !important;
  border-radius: 50px;
  border: 1px solid #CDCDCD;
  position: relative;
  color: #000;
  font-weight: 800;
}

.medflit-item h5 {
  font-weight: 700;
}
.medflit-item:hover {
  
  box-shadow: 20px 30px 50px rgba(203, 203, 203, 0.25);
  transition: 1s;
}

.medflit-item p {
  font-weight: 300;
  color: #111111;
  font-size: 14px;
}

/* Testimonials Section */

.testimonials {
  padding: 100px 200px 50px !important;
  /* margin: 100px 150px 50px; */
}

.testimonial-inner {
  border-radius: 50px;
  padding: 70px 50px 40px 70px;
}

.testimonial-inner h3 {
  font-weight: 900;
  color: #fff;
  font-size: 30px;
  text-align: center;
}

.testimonial-inner h5 {
  font-weight: 800;
  color: #fff;
  font-size: 20px;
}

.testimonial-inner .customer-name {
  margin-top: 0px;
  color: #fff;
  text-align: left;
}


.testimonial-inner p {
  /* margin-top: 30px; */
  color: #fff;
}


/* Footer Section */

.footer {
  padding: 50px 30px 30px 30px;
  margin-top: 1px solid #000 !important;
}

.footer .social-icon {
  margin-right: 20px;
}

.footer .copyright {
  margin-top: 30px;
  font-size: 16px;
  color: #121212;
}

.footer h5 {
  font-weight: 700;
}

.copyright2 {
  display: none;
}

.footer-link a {
  color: black;
  text-decoration: none;
}

/* navigation */

.navvy a {
  color: #fff !important;
  text-decoration: none;
}

.da {
  color: #fff !important;
}

.da a {
  color: black !important;
}

.nl a {
  color: #fff !important;
}

.das {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.nn {
  color: #fff !important;
}

#no {
  color: #1D4BB7 !important;
}
.ni {
  color: #000 !important;
}
.navbar-light .navbar-toggler {
  color: #fff;
  border-color:#fff;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.whatsapp {
  display: none;
}
/* Swiper */

/* .swiper-container {
  width: 480px;
} */

/* @media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
} */
@media screen and (min-width: 1024px) {
  .swiper-container {
    width: 1024px;
  }
} 

/*
@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}  */

@media screen and (max-width: 300px) {
  .swiper-container {
    width: 300px;
  }
}

.ss {
  width: 100% !important;
}

/* Forms */

.justify-center {
  justify-content: center;
}

.bordered {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding: 40px;
}

@media(max-width: 768px) {
  .bordered {
    margin: 40px;
  }
}

/* About Page */

.about h4, h5 {
  font-weight: 800;
  margin-bottom: 20px;
}

.danger {
  color: red;
}