


@media(max-width: 768px) {

    body {
        overflow-x: hidden !important;
    }
    .hero h1,p {
      text-align: center;
    }
  
    .hero h1 {
      font-size: 45px !important;
    }
    .download-btn {
      text-align: center;
    }
    .hero .app-btn {
      width: 130px;
    }

    /* Amazing Benefits Section */

    .benefits h4 {
        text-align: center;
    }

    .benefits p {
        text-align: center;
    }

    .benefits img{
        text-align: center !important;
        margin-left: 30% !important;
    }
    .border-right {
        border-right: none !important;
    }

    .border-around {
        margin: 20px;
    }

    .border-top_ {
    border-top: 1px solid #cdcdcd;
    }

    /* Pricing Section */

    .pricing p {
        text-align: left;
    }

    .pricing-plan {
        margin-top: 0px;
    }

    /* Download App Section */

    .download-app {
        padding: 0px !important;
    }

    .download-inner {
        padding: 50px 20px 40px 20px;
        border-radius: 50px;
    }

    .download-inner h1 {
        font-size: 30px;
        text-align: center;
    }

    .download-inner p {
        text-align: center;
    }

    .download-inner .app-btn {
        width: 130px;
    }

    /* Why Medflit */

    .medflit-item {
        margin: 10px;
    }

    .medflit-item p {
        text-align: left;
    }

    /* Testimonials Section */

    .testimonials {
        padding: 0px !important;
    }
    .testimonial-inner {
        border-radius: 70px;
        padding: 70px 30px 40px 30px;
    }

    .testimonial-inner h3 {
        font-weight: 900;
        color: #fff;
        font-size: 24px;
    }

    .testimonial-inner p {
        margin-top: 20px;
        font-size: 14px; 
        text-align: center !important;
    }

    .testimonial-inner h5 {
        text-align: center !important;
    }

    /* Footer Section */

    .footer p {
        text-align: left;
    }

    .footer h5 {
        margin-top: 30px;
    }

    .copyright2 {
        display: block;
    }

    .whatsapp {
        display: block;
    }
      
    .about h4, h5, p {
        text-align: left;
    }

    .wcyd h5 {
        text-align: center !important;
    }

    .wcyd p {
        text-align: center !important;
    }

    .hiw p {
        text-align: center !important;
    }
}
